<template>
  <v-container class="ticket general">
    <page-title :component="'GroupTickets'"></page-title>
    <v-layout row wrap>
      <v-flex class="xs12 mb-4">
        <v-card class="border-radius">
          <div class="grey lighten-5">
            <v-layout
              class="row wrap text-xs-left pb-2 pl-4 pr-3 align-items-center"
            >
              <v-flex md4 xs6 class="ml-auto">
                <v-select
                  v-model="redeemed"
                  :items="filters"
                  item-text="name"
                  item-value="value"
                  :label="$t('status')"
                  @input="listGroupTickets"
                  hide-details
                ></v-select>
              </v-flex>
              <v-flex md4 xs6>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  :label="$t('search')"
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                <v-divider></v-divider>
              </v-flex>
              <v-flex class="xs11 py-3 pl-4 table-header">
                <v-layout row wrap>
                  <v-flex xs3
                    >{{ $tc('name', 1) }} {{ $tc('surname', 1) }}</v-flex
                  >
                  <v-flex xs3 pr-3>{{ $tc('email', 1) }}</v-flex>
                  <v-flex xs2 pr-3>{{ $tc("phone", 1) }}</v-flex>
                  <v-flex xs2 pr-3>{{ $t("ticket-id") }}</v-flex>
                  <v-flex xs2 pr-3>Checked in</v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </div>
          <div v-if="!isLoading">
            <div
              @click="showTicket(ticket.id)"
              v-for="(ticket, i) in tickets"
              :key="'ticket-' + i"
            >
              <v-layout row wrap class="align-items-center hover">
                <v-flex xs12>
                  <v-divider></v-divider>
                </v-flex>
                <v-flex class="xs12 py-2 pl-4">
                  <v-layout row wrap class="align-items-center">
                    <v-flex md11 xs10>
                      <v-layout row wrap>
                        <v-flex md3 xs12 class="text-ellipsis">
                          <strong>
                            {{ ticket.user.first_name }}
                            {{ ticket.user.last_name }}
                          </strong>
                        </v-flex>
                        <v-flex md3 xs12 pr-3 class="text-ellipsis">{{ ticket.user.email }}</v-flex>
                        <v-flex md2 xs12 pr-3 class="text-ellipsis">
                          <span v-if="ticket.user.phone_code"
                            >+{{ ticket.user.phone_code }}</span
                          >
                          {{ ticket.user.phone }}
                        </v-flex>
                        <v-flex md2 xs12 pr-3>{{ ticket.code }}</v-flex>
                        <v-flex md2 xs12 pr-3>{{ transformDate(ticket.redeemed_at) }}</v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex md1 xs2 text-xs-right py-2 pr-3>
                      <!--
                      <v-btn
                        v-if="ticket.redeemed"
                        flat
                        small
                        color="grey"
                        @click.stop="checkOutTicket(ticket.id)"
                        >Check-out</v-btn
                      >
                      <v-btn
                        v-if="!ticket.redeemed"
                        flat
                        small
                        color="accent"
                        @click.stop="checkInTicket(ticket.code)"
                        >Check-in</v-btn
                      >
                      -->
                      <v-btn
                        flat
                        icon
                        small
                        color="grey"
                        @click.stop="printTicket(ticket)"
                      >
                        <font-awesome-icon icon="print" />
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </div>
          </div>
        </v-card>
      </v-flex>
      <v-flex v-if="totalPages > 1" class="xs12 text-xs-center">
        <v-pagination
          v-model="page"
          :length="totalPages"
          :total-visible="totalVisible"
          @input="listGroupTickets"
        ></v-pagination>
      </v-flex>
    </v-layout>
    <!--
    <TicketModal
      :groupId="groupId"
      :groupPluginId="groupPluginId"
      :ticketTemplateId="ticketTemplateId"
      ref="ticketModalRef"
      @checked-in="listGroupTickets"
    />
    <TicketWithTokenModal
      v-if="usePhysicalToken"
      :groupId="groupId"
      :groupPluginId="groupPluginId"
      :ticketTemplateId="ticketTemplateId"
      ref="ticketModalRef"
      @checked-in="listGroupTickets"
    />
    <TicketModal
      v-if="!usePhysicalToken"
      :groupId="groupId"
      :groupPluginId="groupPluginId"
      :ticketTemplateId="ticketTemplateId"
      ref="ticketModalRef"
      @checked-in="listGroupTickets"
    />
    -->
    <v-dialog v-model="ticketModal" width="600" persistent>
      <v-card class="border-radius">
        <v-card-actions
          ><v-spacer></v-spacer
          ><v-btn icon @click="closeTicketModal">
            <v-icon small>close</v-icon></v-btn
          ></v-card-actions
        >
        <v-card-text class="text-center pt-0">
          <div class="mb-5 sw-h6">{{ $t('tickets.find_user') }}</div>
          <div class="mb-5">
            <v-combobox
              v-model="selectedUser"
              :items="users"
              item-text="name"
              item-value="id"
              return-object
              no-filter
              :search-input.sync="userSearchInput"
              @input="findTickets"
              :label="$t('tickets.selected_user_label')"
              hide-details
            >
              <template slot="item" slot-scope="data">
                <v-layout row class="align-center slot-item">
                  <v-flex xs6>
                    {{ data.item.name }}
                  </v-flex>
                  <v-flex xs6 class="caption text-right">
                    {{ data.item.email }}
                  </v-flex>
                </v-layout>
              </template>
            </v-combobox>
          </div>
          <v-btn
            round
            class="sw-secondary-bg sw-on-secondary text-none"
            @click="generateTicket"
            :disabled="selectedUser && selectedUser.id ? false : true"
            :loading="isProcessing"
            >{{ $t('tickets.generate_ticket_btn') }}</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <AppSpeedDial
      @click="ticketModal = true"
      :hint="$t('tickets.generate_ticket_hint')"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
// import TicketModal from "@/components/Checkin/Modal/TicketModal.vue";
// import TicketWithTokenModal from "@/components/Checkin/Modal/TicketWithTokenModal.vue";

export default {
  data: () => ({
    isLoading: false,
    isProcessing: false,
    plugin: {},
    tickets: [],
    redeemed: 0,
    search: null,
    timeout: null,
    page: null,
    perPage: 15,
    totalPages: null,
    totalVisible: 5,
    ticketModal: false,
    userSearchTimeout: null,
    userSearchInput: '',
    users: [],
    selectedUser: null,
  }),
  computed: {
    ...mapGetters(['getLeftMenu']),
    groupId() {
      return this.$route.params.group_id;
    },
    groupPluginId() {
      return this.$route.params.group_plugin_id;
    },
    ticketTemplateId() {
      return this.$route.params.ticket_template_id;
    },
    currentPage() {
      if (this.$route.query.page) {
        return Number(this.$route.query.page);
      }
      return 1;
    },
    // usePhysicalToken() {
    //   return this.plugin.attributes &&
    //     this.plugin.attributes.ticketwizard_wardrobe_enabled
    //     ? true
    //     : false;
    // },
    filters() {
      return [
        {
          name: this.$t('all'),
          value: null,
        },
        {
          name: this.$t('not-checked-in'),
          value: 0,
        },
        {
          name: this.$t('is-checked-in'),
          value: 1,
        },
      ];
    },
  },
  components: {
    // TicketModal,
    // TicketWithTokenModal,
  },
  mounted() {
    this.page = this.currentPage;
    this.listGroupTickets();
  },
  methods: {
    query(page) {
      this.$router
        .replace({
          query: {
            page: page,
          },
        })
        .catch((err) => {});
    },
    getGroupPlugin() {
      const params = [this.groupId, this.groupPluginId, { with_attributes: 1 }];

      return this.$api.groupPlugins.get(...params);
    },
    // checkInTicket(code) {
    //   if (!this.$refs.ticketModalRef && !code) return;

    //   if (this.usePhysicalToken) {
    //     this.$refs.ticketModalRef.checkTicket(code);
    //   } else {
    //     this.$refs.ticketModalRef.checkInTicket(code);
    //   }
    // },
    checkOutTicket(id) {
      if (!id) return;

      const params = [
        this.groupId,
        id,
        {
          ticket_template_id: this.ticketTemplateId,
        },
      ];

      this.$api.groupTickets
        .checkout(...params)
        .then((response) => {
          this.listGroupTickets();
        })
        .catch((error) => this.errorMessageShow(error));
    },
    async listGroupTickets() {
      this.isLoading = true;

      const res = await this.getGroupPlugin();

      if (!res || !res.data.data) return;

      this.plugin = res.data.data;

      const params = [
        this.groupId,
        {
          group_plugin_id: this.groupPluginId,
          ticket_template_id: this.ticketTemplateId,
          search: this.search ? this.search : null,
          redeemed: this.redeemed,
          page: this.page,
          per_page: this.perPage,
        },
      ];

      this.$api.groupTickets
        .list(...params)
        .then((response) => {
          this.isLoading = false;

          this.tickets = response.data.data;
          this.totalPages = response.data.pagination.total_pages;
          this.query(this.page);
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    showTicket(ticket_id) {
      if (ticket_id) {
        this.$router
          .push({
            name: 'tickets_show',
            params: {
              ticket_id: ticket_id,
              group_plugin_id: this.groupPluginId,
            },
          })
          .catch((error) => this.errorMessageShow(error));
      }
    },
    printTicket(ticket) {
      if (!ticket) return;

      const params = [
        this.groupId,
        ticket.id,
        {
          group_plugin_id: this.groupPluginId,
          ticket_template_id: this.ticketTemplateId,
        },
      ];

      this.$api.groupTickets
        .delete_pdf(...params)
        .then((response) => {
          window.open(
            `${process.env.VUE_APP_API_URL}groups/${this.groupId}/tickets/${ticket.security_token}/${ticket.id}`,
            '_blank',
          );
        })
         .catch((error) => this.errorMessageShow(error));
    },
    closeTicketModal() {
      this.ticketModal = false;
      this.userSearchInput = '';
      this.selectedUser = null;
    },
    async findTickets() {
      try {
        if (!this.selectedUser || !this.selectedUser.email) return;

        this.isProcessing = true;

        const params = [
          this.groupId,
          {
            group_plugin_id: this.groupPluginId,
            ticket_template_id: this.ticketTemplateId,
            search: this.selectedUser.email,
            page: 1,
            per_page: 10,
          },
        ];

        const response = await this.$api.groupTickets.list(...params);

        if (response.data.data.length) {
          this.$store.dispatch('addErrorNotification', {
            message: this.$t('tickets.already_has_ticket_notification', {
              user: this.selectedUser.name,
            }),
          });

          this.selectedUser = null;
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
      }
    },
    async generateTicket() {
      try {
        if (!this.selectedUser || !this.selectedUser.id) return;

        this.isProcessing = true;

        const specs = [
          this.groupId,
          {
            ticket_template_id: this.ticketTemplateId,
            user_id: this.selectedUser.id,
          },
        ];

        const response = await this.$api.groupTickets.generate(...specs);

        if (response.data.data.id) {
          this.$store.dispatch('addNotification', {
            message: this.$t('tickets.ticket_generated_notification', {
              user: this.selectedUser.name,
            }),
          });

          this.selectedUser = null;

          this.listGroupTickets();
        }

        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
      }
    },
  },
  watch: {
    search() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.page = 1;
        this.listGroupTickets();
      }, 800);
    },
    userSearchInput(val) {
      if (!val) {
        this.users = [];
        return;
      };

      clearTimeout(this.userSearchTimeout);

      this.userSearchTimeout = setTimeout(async () => {
        const params = [
          this.groupId,
          {
            search: encodeURIComponent(val),
            page: 1,
            per_page: 10,
          },
        ];

        const response = await this.$api.groupUsers.list(...params);

        this.users = response.data.data.map((user) => ({
          id: user.id,
          email: user.email,
          name: `${user.first_name} ${user.last_name}`,
        }));
      }, 800);
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket {
  @media screen and (max-width: 959px) {
    .table-header {
      display: none;
    }
  }
}
</style>

